import React from "react";
import ChannelsPagination from "./ChannelsPagination";

const SubscriptionsPage = () => {
  return <>
    <ChannelsPagination type={"subscriptions"}/>
  </>
}

export default SubscriptionsPage;
