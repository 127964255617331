import React from 'react';

const ErrorPage = () => {
    return (
        <div>
          <h1 style={{color: "white", fontSize: 50}}>404 not found</h1>
        </div>
    );
};

export default ErrorPage;
